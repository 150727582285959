import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth/useAuth';
import { useOpenAvaliationModal } from '../../contexts/AvaliationModal/useOpenAvaliationModal';
import { useOpenCreateGalleryModal } from '../../contexts/CreateGalleryModal/useOpenCreateGalleryModal';
import { useOpenGalleryModal } from '../../contexts/GalleryModal/useOpenGalleryModal';
import { useOpenSignIn } from '../../contexts/Modal/useOpenSignIn';
import { useFilterStore } from '../../hooks/useContentFilters/useFilterStore';
import useGenreId from '../../hooks/useGenreId/useGenreId';
import useSearchInput from '../../hooks/useSearchInput/useSearchInput';
import { GenresRoute } from '../../pages/Genres/constants/GenresEnum';
import { AvaliationModal } from '../AvaliationModal/index';
import { CreateGalleryModal } from '../CreateGalleryModal';
import { GalleryModal } from '../GalleryModal';
import { SignIn } from '../SignIn';
import { UserInfo } from '../UserInfo';

import * as C from './styles';
import { bannerSizeHome } from '../../hooks/useBannerDefault/bannerSizes';
import { useBannerDefault } from '../../hooks/useBannerDefault/useBannerDefault';
//import { useBannerDefault } from './hooks/useBannerDefault';

export const Header = ({ position }) => {
  const [blackHeader, setBlackHeader] = useState(false);
  const searchInput = useSearchInput();
  const modal = useOpenSignIn();
  const modalAvaliation = useOpenAvaliationModal();
  const modalGallery = useOpenGalleryModal();
  const createModal = useOpenCreateGalleryModal();
  const navigate = useNavigate();
  const { setStore, setGenreName } = useGenreId();
  const { actions } = useFilterStore();

  const param = 'topo';
  const divName = 'banner-topo';
  const page = 'feed';

  const sponsor = {
    param,
    divName,
    page,
  };

  useBannerDefault(sponsor, bannerSizeHome);

  //useBannerDefault(param, divName, page);
  //const { width } = useWindowSize();
  //const sponsorImage = loadSponsor(width);

  const goToHome = () => {
    searchInput.setValue('');
    setStore('');
    setGenreName('');
    actions.reset();
    navigate('/');
  };

  const { pathname } = useLocation();

  const auth = useAuth();

  const handleButtonClick = () => {
    modal.setOpenSignIn(!modal.openSignIn);
  };
  useEffect(() => {
    if (
      modal?.openSignIn ||
      modalAvaliation?.openAvaliationModal ||
      modalGallery?.openGalleryModal ||
      createModal?.openGalleryModal
    ) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [
    modal?.openSignIn,
    modalAvaliation?.openAvaliationModal,
    modalGallery?.openGalleryModal,
    createModal?.openGalleryModal,
  ]);

  useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY > 10) {
        setBlackHeader(true);
      } else {
        setBlackHeader(false);
      }
    };

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  const redirectToLooke = () => {
    window.open('https://bit.ly/3SgpUAq', '_blank');
  };

  return (
    <C.Wrapper>
      <C.Header isBlack={blackHeader} style={{ position: position }}>
        <div className='content-logo' onClick={goToHome}>
          <img className='logo' src='/assets/images/logo.png' alt='logo' />
        </div>
        <C.Container className='showComponent'>
          <ul>
            <li className='right'>
              <Link to='/category/movies'>FILMES</Link>
            </li>
            <li className='right'>
              <Link to='/category/series'>SÉRIES</Link>
            </li>
            <li>
              <Link to={GenresRoute.GENRES}>GÊNEROS </Link>
            </li>
            <li>
              <Link to='/lists'>COLEÇÕES </Link>
            </li>
            {auth.isLoggedIn && (
              <li>
                <Link to='/profile?step=1'>QUERO VER</Link>
              </li>
            )}
          </ul>
        </C.Container>

        {!auth.isLoggedIn ? (
          <C.Button onClick={handleButtonClick}>LOG!N</C.Button>
        ) : (
          <UserInfo />
        )}
        {modal?.openSignIn && <SignIn />}
        {modalAvaliation?.openAvaliationModal && <AvaliationModal />}
        {modalGallery?.openGalleryModal && <GalleryModal />}
        {createModal?.openGalleryModal && <CreateGalleryModal />}

        <C.ModalBackground
          isActive={
            createModal?.openGalleryModal ||
            modalGallery?.openGalleryModal ||
            modalAvaliation?.openAvaliationModal
          }
          onClick={() => {
            if (createModal.openGalleryModal) {
              createModal?.setOpenGalleryModal(false);
            }
            if (modalAvaliation.openAvaliationModal) {
              modalAvaliation?.setOpenAvaliationModal(false);
            } else {
              modalGallery?.setOpenGalleryModal(false);
            }
          }}
        />
      </C.Header>

      <C.Sponsor onClick={redirectToLooke}>
        {/* <img src={sponsorImage} alt='' /> */}

        {!(
          pathname === '/' ||
          pathname.startsWith('/movie/') ||
          pathname.startsWith('/serie/')
        ) && <div id={divName}></div>}
      </C.Sponsor>
    </C.Wrapper>
  );
};
