import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 950px) {
  * {
    cursor: none !important;
  }
}

body {
  background-color: #121212;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}

button {
  font-family: 'Montserrat', sans-serif;
}

input {
  font-family: 'Montserrat', sans-serif;
}

.Toastify__toast {
  background: #121212 !important;


}

.Toastify__toast-body > div:last-child {
    flex: none!important;;
    color: #FFF;
}

.Toastify__progress-bar--error  {
  background-color: #db1414!important;
}

.Toastify__progress-bar--success {
  background-color: #F205B3!important;
}

.Toastify__toast-icon {
  svg {
    fill: #F205B3!important
  }
}

.Toastify__toast-body {
  color: #444C63    ;
  font-size: 16px;
  line-height: 20px;
  margin: 0 auto !important;
  flex: none!important;
}
.Toastify__toast > button>  svg {
  color: #fff;
}

.swiper-button-next::after, .swiper-button-prev::after{
  display: none !important;
}

.swiper-button-next {
  background-image:  url('/assets/images/rightarrow.png');
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  opacity: 0.5;
  transition: ease-in-out .5s;
}

.swiper-button-prev {
  background-image: url('/assets/images/leftarrow.png');
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  opacity: 0.5;
  transition: ease-in-out .5s;
}

.swiper-button-prev {
  left: -1px !important; /* Ajuste horizontal para mover a seta para fora */
}

.swiper-button-next {
  right: -1px !important; /* Ajuste horizontal para mover a seta para fora */
}


.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}

a {
  text-decoration: none;
}

@media (max-width: 728px) {
.swiper-button-next {
  background-image:  none;

}

.swiper-button-prev {
  background-image: none;
}

}

`;
